import {
  CreateInstrumentDto,
  PartialInstrumentFormProps,
} from 'pages/Instruments/Instruments.types';
import { ParamsType, getQueryString } from 'hooks/useUrlParams';
import { apiUrls } from 'utils/constants/apiUrls';
import { axiosInstance } from 'utils/axiosOnyx';
import { errorHandler } from 'utils/apiUtils';
import { getEtpDetailsFormDataFormatted } from 'pages/Instruments/components/Form/utils';
import {
  Instrument,
  InstrumentAPs,
  Order,
  OrderFormProps,
  PaginationWithDataResponse,
  ProductsTradingByDate,
  DailyProductsTrading,
} from 'utils/types';
import { editEntityApiCall } from './crudActions';

export async function submitForApprovalInstrument(id: Instrument['_id']) {
  try {
    const { data: instrument } = await axiosInstance.post<Instrument>(
      `${apiUrls.product}/id=${id}/submit`
    );
    return instrument;
  } catch (err) {
    return errorHandler(err, 'Error while submitting instrument');
  }
}

export async function createInstrument(dto: CreateInstrumentDto) {
  try {
    const { data: instrument } = await axiosInstance.post<Instrument>(apiUrls.product, dto);
    return instrument;
  } catch (err) {
    return errorHandler(err, 'Error while creating instrument');
  }
}

export async function saveInstrumentApiCall(
  uri: string,
  instrumentProps: PartialInstrumentFormProps
) {
  const { data: instrument } = await editEntityApiCall<
    ReturnType<typeof getEtpDetailsFormDataFormatted>,
    Instrument
  >(getEtpDetailsFormDataFormatted(instrumentProps), uri, 'Error while updating instrument');
  return instrument;
}

export async function loadInstrumentCustodians(
  id: string,
  status: string = 'ACTIVE'
): Promise<Instrument['custodianAccounts']> {
  try {
    const { data: custodianAccounts } = await axiosInstance.get<
      Instrument['custodianAccounts'] | PaginationWithDataResponse<Instrument['custodianAccounts']>
    >(`${apiUrls.product}/id=${id}/custodian-accounts?${getQueryString({ status: status })}`);
    return custodianAccounts as Instrument['custodianAccounts'];
  } catch (err) {
    return errorHandler(err, "Error while loading instrument's custodian accounts");
  }
}

export async function loadInstrumentAPs(id: string) {
  try {
    const { data: authorizedParticipants } = await axiosInstance.get<InstrumentAPs[]>(
      `${apiUrls.product}/id=${id}/authorized-participants`
    );
    return authorizedParticipants;
  } catch (err) {
    return errorHandler(err, "Error while loading instrument's authorized participants");
  }
}

export async function loadInstruments(params?: ParamsType) {
  try {
    const { data: instruments } = await axiosInstance.get<PaginationWithDataResponse<Instrument[]>>(
      params ? `${apiUrls.product}?${getQueryString(params)}` : apiUrls.product
    );
    return instruments;
  } catch (err) {
    return errorHandler(err, 'Error while loading instruments');
  }
}

export async function loadInstrumentsBaseData(params?: ParamsType) {
  try {
    const intrumentsResponse = await axiosInstance.get<PaginationWithDataResponse<Instrument[]>>(
      params ? `${apiUrls.product}?${getQueryString(params)}` : apiUrls.product
    );
    return intrumentsResponse.data;
  } catch (err) {
    return errorHandler(err, 'Error while loading instruments');
  }
}

export async function loadInstrumentWallets(
  instrumentId: string,
  status: string = 'ACTIVE'
): Promise<Pick<Instrument, 'custodianWallets' | 'unifiedWallets'>> {
  try {
    const { data: walletC = [] } = await axiosInstance.get<Instrument['custodianWallets']>(
      `${apiUrls.product}/id=${instrumentId}/wallets/type=CUSTODIAN?${getQueryString({
        status: status,
      })}`
    );

    return { custodianWallets: walletC };
  } catch (err) {
    return errorHandler(err, "Error while loading instrument's wallets");
  }
}

export async function loadProductData(id: Instrument['_id']) {
  try {
    const { data: instrument } = await axiosInstance.get<Instrument>(`${apiUrls.product}/id=${id}`);
    return instrument;
  } catch (err) {
    return errorHandler(err, 'Error while loading instrument data');
  }
}

export async function loadInstrument(
  id: string,
  options?: { withWallets?: boolean; withCustodians?: boolean }
): Promise<Instrument> {
  const { withWallets, withCustodians } = options || {};

  try {
    const instrument = await loadProductData(id);
    const walletPromise = withWallets ? loadInstrumentWallets(id) : null;
    const custodianPromise = withCustodians ? loadInstrumentCustodians(id) : null;
    const [walletsResponse, custodianAccountsResponse] = await Promise.allSettled([
      walletPromise,
      custodianPromise,
    ]);

    if (walletsResponse.status === 'fulfilled') {
      instrument.custodianWallets = walletsResponse.value?.custodianWallets;
      instrument.unifiedWallets = walletsResponse.value?.unifiedWallets;
    }

    if (custodianAccountsResponse.status === 'fulfilled') {
      instrument.custodianAccounts = custodianAccountsResponse.value ?? [];
    }

    return instrument;
  } catch (err) {
    return errorHandler(err, 'Error while loading instrument');
  }
}

export async function loadOrderDetails(orderProps: OrderFormProps): Promise<Order> {
  const queryList = getQueryString({
    deliveryType: orderProps.deliveryType,
    orderType: orderProps.type,
    numberOfUnits: orderProps.numberOfUnits,
    userId: orderProps.userId ?? '',
    companyId: orderProps.companyId ?? '',
    pricingType: orderProps.pricingType ?? '',
    cashExecutionFeeBps: orderProps.cashExecutionFeeBps ?? null,
    settlementType: orderProps.settlementType,
    deliveryCurrency: orderProps.deliveryCurrency ?? '',
  });

  try {
    const { data: order } = await axiosInstance.get<Order>(
      `${apiUrls.product}/id=${orderProps.productId}/order-details?${queryList}`
    );

    return order;
  } catch (err) {
    return errorHandler(err, 'Error while loading order details');
  }
}

export async function getProductsTradingToday(
  valuationDateStart?: string,
  valuationDateEnd?: string
): Promise<ProductsTradingByDate> {
  try {
    const params: Record<string, string> = {};
    if (valuationDateStart && typeof valuationDateStart === 'string') {
      params.valuationDateStart = valuationDateStart;
    }
    if (valuationDateEnd) {
      params.valuationDateEnd = valuationDateEnd;
    }

    const { data } = await axiosInstance.get<ProductsTradingByDate>(
      `${apiUrls.productsTradingToday}`,
      { params }
    );

    return data;
  } catch (err) {
    return errorHandler(err, 'Error while fetching products trading today');
  }
}

export function checkIfHoliday(productsTradingByDate: ProductsTradingByDate): boolean {
  return Object.values(productsTradingByDate).some(
    (dayTrading: DailyProductsTrading) => dayTrading.notTrading.length > 0
  );
}
