import useAppDispatch from 'hooks/useAppDispatch';
import useAppModal from 'hooks/useAppModal';
import {
  cancelOrder as cancelOrderRequest,
  confirmOrder as confirmOrderRequest,
  deferOrder as deferOrderRequest,
  readyOrder as readyOrderRequest,
  settleOrder as settleOrderRequest,
  confirmTokenOrder as confirmTokenOrderRequest,
  cancelTokenOrder as cancelTokenOrderRequest,
  readyTokenOrder as readyTokenOrderRequest,
  settleTokenOrder as settleTokenOrderRequest,
} from 'utils/api/orders';
import { Deliverable, Instrument, NotificationWithoutId, Order, ProductType } from 'utils/types';
import { ETP_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { createNotification } from 'store/notifications/actions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { queryClient } from 'utils/api/queries/queryClient';
import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { ACTIVATE_ETP_MODAL_SEED } from 'shared/Modals/Product/ProductModal';
import { ORDER_NOTIFICATIONS } from './consts';
import { FeesData } from 'shared/Modals/Orders/OrderStatusChangeModal/components/FeesTable';

export const useOrdersActions = (
  orderId?: string,
  refreshQueryKey?: string,
  productType: ProductType = 'ETP'
) => {
  const dispatch = useAppDispatch();
  const openModal = useAppModal();

  const onSuccess = ({ title, message }: Omit<NotificationWithoutId, 'type'>) => {
    dispatch(createNotification(successNotification(message ?? '', title ?? '')));
    refreshOrders();
  };

  const onError = (err: unknown) => {
    const error = err as Error;
    dispatch(createNotification(errorNotification(error.message, 'Error'), error));
  };

  const confirmOrder = useMutation({
    mutationFn: ({
      orderId,
      actualDeliverables,
    }: {
      orderId: string;
      actualDeliverables?: Deliverable[];
    }) =>
      productType === 'ETP'
        ? confirmOrderRequest(orderId, actualDeliverables)
        : confirmTokenOrderRequest(orderId),
    onSuccess: () => {
      onSuccess(ORDER_NOTIFICATIONS.CONFIRMED);
    },
    onError,
  });

  const cancelOrder = useMutation({
    mutationFn: productType === 'ETP' ? cancelOrderRequest : cancelTokenOrderRequest,
    onSuccess: () => {
      onSuccess(ORDER_NOTIFICATIONS.CANCELLED);
    },
    onError,
  });

  const readyOrder = useMutation({
    mutationFn: productType === 'ETP' ? readyOrderRequest : readyTokenOrderRequest,
    onSuccess: () => {
      onSuccess(ORDER_NOTIFICATIONS.READY);
    },
    onError,
  });

  const readyOrderWithFees = useMutation({
    mutationFn: (data: { id: string; fees?: Omit<FeesData, 'totalFees'> | null }) =>
      readyOrderRequest(data.id, data.fees),
    onSuccess: () => {
      onSuccess(ORDER_NOTIFICATIONS.READY);
    },
    onError,
  });

  const deferOrder = useMutation({
    mutationFn: deferOrderRequest,
    onSuccess: () => {
      onSuccess(ORDER_NOTIFICATIONS.DEFERRED);
    },
    onError,
  });

  const settleOrder = useMutation({
    mutationFn: ({
      orderId,
      actualDeliverables,
    }: {
      orderId: string;
      actualDeliverables?: Deliverable[];
    }) =>
      productType === 'ETP'
        ? settleOrderRequest(orderId, actualDeliverables)
        : settleTokenOrderRequest(orderId, actualDeliverables),
    onSuccess: (res) => {
      onSuccess(ORDER_NOTIFICATIONS.SETTLED);
      if (res.tags?.includes('seed') && res.product) {
        const productData = { _id: res.product._id, name: res.product.name };
        openModal({
          modalName: ETP_MODAL,
          modalData: {
            data: productData as Instrument,
            type: MODAL_ACTIONS.CREATE,
            custom: { type: ACTIVATE_ETP_MODAL_SEED, isToken: productType === 'Token' },
          },
        });
      }
    },
    onError,
  });

  const refreshOrders = () => {
    if (refreshQueryKey) {
      queryClient.invalidateQueries({ queryKey: [refreshQueryKey] });
    }
  };

  const downloadPdf = useCallback(
    (order: Order) => {
      if (order.documents?.confirmationPdf?.url) {
        window.open(order.documents?.confirmationPdf?.url);
      }
    },
    [orderId]
  );

  const confirmAction = (actualDeliverables?: Deliverable[]) =>
    orderId && confirmOrder.mutate({ orderId, actualDeliverables });
  const cancelAction = () => orderId && cancelOrder.mutate(orderId);
  const deferAction = () => orderId && deferOrder.mutate(orderId);
  const readyAction = () => orderId && readyOrder.mutate(orderId);
  const readyActionWithFees = (fees: Omit<FeesData, 'totalFees'> | null) =>
    orderId && readyOrderWithFees.mutate({ id: orderId, fees });
  const settleAction = (actualDeliverables?: Deliverable[]) =>
    orderId && settleOrder.mutate({ orderId, actualDeliverables });

  return {
    downloadPdf,
    cancelAction,
    confirmAction,
    deferAction,
    readyAction,
    readyActionWithFees,
    settleAction,
  };
};
