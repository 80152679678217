import { IN_KIND, CASH } from 'utils/constants';
import { CustodianStatus } from 'utils/constants/custodians';
import { ProductCustodianWallet, WalletStatus } from './wallets';
import { Actions, Links } from '.';
import { TimeZone } from 'utils/constants/calendar';

/* -----
PRODUCTS
-----*/

export const isEtpConstituent = (
  constituent: TokenConstituentAssetType | ProductConstituentAssetType | undefined
): constituent is ProductConstituentAssetType =>
  Boolean(constituent) &&
  ('updatedBy' in constituent! || 'updatedAt' in constituent! || 'status' in constituent!);

export type ProductType = 'ETP' | 'Token';

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
  IN_REVIEW = 'IN_REVIEW',
  ARCHIVED = 'ARCHIVED',
}

export enum ConstituentAssetStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Partner {
  id: string;
}

export type ProductBaseActions =
  | 'activate'
  | 'update'
  | 'delete'
  | 'archive'
  | 'submitForApproval'
  | 'createDelegatedOrder'
  | 'createOrder';

export interface ProductBase {
  _actions?: Actions<ProductBaseActions>;
  _id: string;
  _links?: Links;
  createdAt: string;
  issuer: Partner;
  name: string;
  status: ProductStatus;
  ticker: string;
  type: ETPType | TokenType; // will not be the same values for ETPs vs Tokens
  updatedAt: string;
}

export interface CustodianAccount {
  _id: string;
  apiKey?: string;
  apiPassphrase?: string;
  productId?: string;
  provider: string;
  signatureKey?: string;
  name: string;
  description: string;
  status: CustodianStatus;
  _actions?: Actions;
}

export interface ConstituentAssetType {
  _id?: string;
  coingeckoId?: string;
  ticker: string;
  name: string;
  assetType: AssetClass;
  rounding: number;
  isStaking?: boolean;
  isMev?: boolean;
  stakingFee?: number;
  interestGenerating?: boolean;
  leverageRatio?: number;
  isLeveraged?: boolean;
  pairAsset?: string;
  rebalanceFeeBuyBps?: number;
  rebalanceFeeSellBps?: number;
}

export interface TokenConstituentAssetType extends ConstituentAssetType {}
export interface ProductConstituentAssetType extends ConstituentAssetType {
  isActive: boolean;
  updatedBy?: string;
  updatedAt?: string;
}

export type GeneralConstituentAssetFormType = Partial<ConstituentAssetType> & {
  isActive?: boolean;
};

export interface ConstituentAssetPayload {
  coingeckoId?: string;
  ticker: string;
  name: string;
  type: string;
  rounding: number;
}

export type ProductConstituentAssetsProps = ProductConstituentAssetType[];
export type TokenConstituentAssetsProps = TokenConstituentAssetType[];

/* -----
ETPs
----- */
export type AllowedDeliveries = typeof IN_KIND | typeof CASH;

export enum DeliveryCurrencyType {
  CASH = 'CASH',
  CRYPTO = 'CRYPTO',
}

export interface DeliveryCurrency {
  assetId: string;
  ticker: string;
  type: DeliveryCurrencyType;
}
export type AssetClass = 'Crypto' | 'Metal' | 'Cash';
export enum ETPType {
  INDEX = 'Index',
  SINGLE_ASSET = 'Single Asset',
}
export type Currency = 'USD' | 'CHF' | 'EUR';
export type RebalanceFrequency = 'Daily' | 'Monthly' | 'Quarterly' | 'Semi-Annually' | 'Annually';
export type RebalanceFeeCalculationMethod = 'MULTIPLIER' | 'DIVISOR';

export enum RebalanceStrategy {
  'PASSIVE_INDEX' = 'PASSIVE_INDEX',
  'SHORT_STRATEGY' = 'SHORT_STRATEGY',
  'LEVERAGED_STRATEGY' = 'LEVERAGED_STRATEGY',
}

export enum RebalanceStrategyLabel {
  'PASSIVE_INDEX' = 'Rules-based passive index',
  'SHORT_STRATEGY' = 'Single Asset Short Strategy',
  'LEVERAGED_STRATEGY' = 'Single Asset Leveraged Strategy',
}

export type StandardSettlement = 'T+1' | 'T+2' | 'T+3';

export enum CustodianProvider {
  COINBASE = 'COINBASE',
  COPPER = 'COPPER',
}

export interface ProductExchangeLocalTicker {
  name: string;
  currency: Currency;
  listingDate: Date;
}

export interface ProductExchange {
  id: string;
  name: string; // replicated data
  primary?: boolean;
  constituentExchange?: boolean;
  localTickers: ProductExchangeLocalTicker[];
}

export interface ProductAuthorizedParticipant {
  id: string;
}

export interface ProductFees {
  adminCreationFee?: number;
  adminRedemptionFee?: number;
  navPlusCreationExecutionFee?: number;
  navPlusRedemptionExecutionFee?: number;
  navGuaranteedCreationExecutionFee?: number;
  navGuaranteedRedemptionExecutionFee?: number;
}

export interface ProductCutoffs {
  inKindCutOffTime?: string;
  cashCutOffTime?: string;
}

interface BaseConstituentAsset {
  _id: string;
  ticker: string;
  name: string;
  coingeckoId: string;
  rounding: number;
  assetType: AssetClass;
  isStaking?: boolean;
  isMev?: boolean;
  stakingFee?: number;
  interestGenerating?: boolean;
  isLeveraged?: boolean;
  leverageRatio?: number;
  pairAsset?: string;
  rebalanceFeeBuyBps?: number;
  rebalanceFeeSellBps?: number;
}

export interface ProductConstituentAsset extends BaseConstituentAsset {
  status: ConstituentAssetStatus;
  updatedBy?: string;
  updatedAt?: string;
}

export interface TokenConstituentAsset extends BaseConstituentAsset {}

export interface ProductUnifiedWallet {
  _id?: string;
  address: string;
  chain: Chain;
  constituentId: string;
  custodianAccount: string;
  description: string;
  idAtCustodian: string;
  status: WalletStatus;
  transactingCompany?: string;
  _actions?: Actions<'delete' | 'update' | 'activate'>;
}

export interface ProductPartners {
  calculationAgents?: Partner[];
  pcfUploaders?: Partner[];
  custodians?: CustodianProvider[];
  technicalListingAgent?: Partner;
}

export interface ProductFactsheet {
  ucitsEligibility: boolean;
  lendingEligibility: boolean;
  replicationMethod: string;
  domicile: string;
  legalStructure: string;
  shariahCompliant: boolean;
  registeredCountries: Array<string>;
  listingDate: Date;
}

export type InstrumentAPs = {
  name: string;
  id: string;
};

export type InstrumentList = {
  [id: string]: Instrument;
};

export interface Instrument extends ProductBase {
  // uuid?: string;
  // General
  type: ETPType;
  unitSize?: number;
  allowedDeliveries?: AllowedDeliveries[];
  allowedDeliveryCurrencies?: DeliveryCurrency[];
  annualFee?: string;
  currency?: string;
  quoteCurrency?: string;
  jurisdiction?: string;
  fundIcon?: string;
  isin?: string;
  seriesLetter?: string;
  seriesName?: string;
  standardSettlement?: StandardSettlement;
  cutoffs?: ProductCutoffs;
  fees?: ProductFees;
  // NAV related props;
  pricingTypeThreshold?: number;
  // Market ETP
  bloombergTicker?: string;
  valor?: string;
  wkn?: string;
  reutersTicker?: string;
  sedol?: string;
  kurzel?: string;
  marketMakers?: string;
  collateralAgent?: string;
  inav?: string;
  cusip?: string;
  // Benchmark ETP
  indexProvider?: string;
  indexProviderSpecificId?: string;
  underlyingTicker?: string;
  rebalancingFrequency?: RebalanceFrequency;
  underlyingIsin?: string;
  rebalancingStrategy?: RebalanceStrategy;
  underlyingWkn?: string;
  underlyingCurrency?: string;
  underlyingName?: string;
  dataAggregator?: string;
  rebalanceFeeCalculationMethod?: RebalanceFeeCalculationMethod;
  // ExchangeEtp
  exchanges?: ProductExchange[];
  // Constituent ETP
  constituentAssets?: ProductConstituentAsset[];
  // Wallets custodians
  custodianWallets?: ProductCustodianWallet[];
  // Wallets Unified
  unifiedWallets?: ProductCustodianWallet[];
  // Partners
  authorizedParticipants?: Partner[];
  partners?: ProductPartners;
  // Factsheet
  factsheet?: ProductFactsheet;
  // custodians
  custodianAccounts?: CustodianAccount[];
  pcfPublishTime?: string;
  pcfPublishTimeTimezone?: TimeZone;
}

/* ---- 
TOKENS
----- */
export enum Chain {
  BITCOIN = 'BITCOIN',
  ETHEREUM = 'ETHEREUM',
  CARDANO = 'CARDANO',
  BITCOINCASH = 'BITCOINCASH',
  LITECOIN = 'LITECOIN',
  STELLARLUMENS = 'STELLARLUMENS',
  NEO = 'NEO',
  TEZOS = 'TEZOS',
  EOS = 'EOS',
  COSMOS = 'COSMOS',
  SOLANA = 'SOLANA',
  AVALANCHE = 'AVALANCHE',
  ALGORAND = 'ALGORAND',
  DOGE = 'DOGE',
}

export enum TokenType {
  SINGLE_ASSET = 'Single Asset',
}

export enum TokenConstituentTicker {
  BTC = 'BTC',
  ADA = 'ADA',
  BCH = 'BCH',
  BNB = 'BNB',
  DOT = 'DOT',
  LTC = 'LTC',
  SOL = 'SOL',
  XRP = 'XRP',
  DOGE = 'DOGE',
  AVAX = 'AVAX',
}

export type TokenStandard = 'ERC_20' | 'SPL_20' | 'SPL_22';

export interface Token extends ProductBase {
  // Token Base
  destinationChain: Chain;
  allowedDeliveryCurrencies?: DeliveryCurrency[];
  fundIcon: string;
  tokenStandard: TokenStandard;
  type: TokenType;
  /*--- 
  TOKEN DETAILS
  ---*/
  // General Details
  contractAddress?: string;
  sourceChain?: Chain;
  // Admin Fees
  fees: {
    burnFees?: string;
    mintFees?: string;
  };
  // Constituent Details
  constituentAssets?: TokenConstituentAsset[];
  /* ---
  PARTNER DETAILS
  --- */
  // Partners
  authorizedParticipants?: Partner[];
  partners?: ProductPartners;
  // Custodian Info
  custodianAccounts?: CustodianAccount[];
  // Wallets Info
  // Wallets custodians
  custodianWallets?: ProductCustodianWallet[];
  // Wallets Unified
  unifiedWallets?: ProductCustodianWallet[];
}

export type TokenList = {
  [id: string]: Token;
};

export type TokenAuthorizedMerchant = {
  name: string;
  id: string;
};
