// components
import Notification from 'components/Notification';
import { StyledCutoffContainer } from 'pages/Orders/Orders/Order/Order.styles';
import { Order } from 'utils/types';

interface CutoffInfoProps {
  deliveryType: string;
  isClosed: boolean;
  product: Order['product'];
}

const CutoffInfo = ({ isClosed, product, deliveryType }: CutoffInfoProps) => {
  const timezone = product.primaryExchange.calendar.timezone;
  const openTime = product.primaryExchange.marketOpen;
  return isClosed ? (
    <StyledCutoffContainer>
      <Notification
        message={`${deliveryType} order market opens at: <br />${openTime} ( ${timezone} )`}
        multiline="true"
        variant="warning"
        withcountdown="false"
      />
    </StyledCutoffContainer>
  ) : null;
};

export default CutoffInfo;
