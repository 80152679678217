import Table from 'components/Table';
import { Order, ProductType } from 'utils/types';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { renderOrderValue } from 'shared/Tables/table.utils';
import Box from '@mui/material/Box';
import { TableBody, Typography } from '@mui/material';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import ExplorerLink from 'components/ExplorerLink';

interface FundInformationProps {
  productType: ProductType;
  className?: string;
  order?: Order;
}

const ProductInformation = ({ productType, className, order }: FundInformationProps) => {
  return (
    <Box className={className} component="section">
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Product Information</Typography>
      </MuiStyledSectionHeader>
      {productType === 'ETP' && (
        <Table noPadding={true}>
          <TableBody>
            <MuiStyledTableRow>
              <MuiStyledTableCell>ETP name</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order?.product.name), order?.product.name)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Series Name</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order?.product.seriesName), order?.product.seriesName)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Series Letter</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(order?.product.seriesLetter),
                  order?.product.seriesLetter
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Product ISIN</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order?.product.isin), order?.product.isin)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          </TableBody>
        </Table>
      )}
      {productType === 'Token' && (
        <Table noPadding>
          <TableBody>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Token name</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order?.product?.name), order?.product?.name)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Source Chain</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(order?.product?.sourceChain),
                  order?.product?.sourceChain
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Destination Chain</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {' '}
                {renderOrderValue(
                  Boolean(order?.product?.destinationChain),
                  order?.product?.destinationChain
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>

            <MuiStyledTableRow>
              <MuiStyledTableCell>Mint/Burn Contract ID</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {(order?.product?.destinationChain && order?.product?.contractAddress && (
                  <ExplorerLink
                    destinationChain={order?.product?.destinationChain}
                    data={order?.product?.contractAddress}
                    type="address"
                  />
                )) ||
                  '-'}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default ProductInformation;
