import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { Notification as NotificationType } from 'utils/types';
import { ReactComponent as IconClose } from 'assets/close.svg';
import { ReactComponent as IconError } from 'assets/error.svg';
import { ReactComponent as IconInfo } from 'assets/info.svg';
import { ReactComponent as IconWarning } from 'assets/warning.svg';
import { ReactComponent as IconSuccess } from 'assets/success.svg';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';

// styles
import {
  MuiStyledDescription,
  MuiStyledMessage,
  MuiStyledTitle,
  MuiStyledNotification,
} from 'components/Notification/Notification.styles';
import NewLink from 'components/Link/NewLink';

interface NotificationProps extends ComponentPropsWithoutRef<'div'> {
  variant?: NotificationType['type'];
  title?: string;
  message?: string;
  messageComponent?: ReactNode;
  highcontrast?: 'true' | 'false';
  multiline?: 'true' | 'false';
  actionText?: string;
  onClose?: () => void;
  action?: () => void;
  withcountdown?: 'true' | 'false';
  messageMaxWidth?: boolean;
  gapBelow?: number;
  emphasis?: 'low' | 'high';
}

const iconVariantMap = {
  primary: IconInfo,
  error: IconError,
  info: IconInfo,
  success: IconSuccess,
  warning: IconWarning,
};

const Notification = ({
  variant = 'info',
  title,
  message,
  multiline = 'true',
  onClose,
  action,
  actionText,
  highcontrast = 'false',
  withcountdown = 'true',
  messageMaxWidth = true,
  messageComponent,
  emphasis = 'high',
  ...props
}: NotificationProps) => {
  const Icon = iconVariantMap[variant];
  const isMultiline = multiline !== 'false';

  return (
    <MuiStyledNotification
      highcontrast={highcontrast}
      withcountdown={withcountdown}
      variant={variant}
      multiline={multiline}
      actionText={actionText}
      emphasis={emphasis}
      {...props}
    >
      <Box className="type-icon">
        <Icon />
      </Box>
      <MuiStyledDescription multiline={multiline} actionText={actionText}>
        {title && (
          <MuiStyledTitle
            maxWidth={messageMaxWidth ? 'max-content' : '340px'}
            multiline={multiline}
            variant={variant}
          >
            {title}
          </MuiStyledTitle>
        )}
        {message && (
          <MuiStyledMessage
            maxWidth={messageMaxWidth ? 'max-content' : '340px'}
            dangerouslySetInnerHTML={{ __html: message }}
            multiline={multiline}
            variant={variant}
          ></MuiStyledMessage>
        )}
        {messageComponent && (
          <MuiStyledMessage maxWidth={messageMaxWidth ? 'max-content' : '340px'} variant={variant}>
            {messageComponent}
          </MuiStyledMessage>
        )}
        {action && actionText && isMultiline && (
          <NewLink
            onClick={action}
            text={actionText}
            variant="secondary"
            className="action-link"
            underline="none"
          />
        )}
      </MuiStyledDescription>
      {action && actionText && !isMultiline && (
        <NewLink
          onClick={action}
          text={actionText}
          variant="secondary"
          className="action-link"
          underline="none"
        />
      )}
      {onClose && (
        <IconButton onClick={onClose} className={`no-hover-button`}>
          <IconClose />
        </IconButton>
      )}
    </MuiStyledNotification>
  );
};

export default Notification;
