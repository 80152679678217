export function getTimeOptionsAfter(initialTime: string) {
  const start = parseInt(initialTime.substring(0, 2));

  return Array.from({ length: 23 - start }, (_, index) => `${start + index + 1}`).map((hour) => {
    const time = `${hour.padStart(2, '0')}:00`;
    return { label: time, value: time };
  });
}

// Time options in 15 min intervals
const timeOptions = Array.from({ length: 24 * 4 }, (_, index) => {
  const hour = Math.floor(index / 4)
    .toString()
    .padStart(2, '0');
  const minutes = ((index % 4) * 15).toString().padStart(2, '0');
  const time = `${hour}:${minutes}`;
  return { label: time, value: time };
});

export const hourOptions = Array.from({ length: 24 }, (_, index) => {
  const hour = index.toString().padStart(2, '0');
  const value = `${hour}:00`;
  return { label: value, value };
});

export const hourOptionsWithEndOfDay = Array.from({ length: 25 }, (_, index) => {
  const hour = index.toString().padStart(2, '0');
  const value = `${hour}:00`;
  return { label: value, value };
});

export default timeOptions;
