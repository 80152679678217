import { TimeZone } from 'utils/constants/calendar';
import { Actions, Links } from '.';

export enum COMPANY_CONTACT_TYPE {
  AFFILIATED = 'AFFILIATED',
  IMPLICIT = 'IMPLICIT',
  UNAFFILIATED = 'UNAFFILIATED',
}

export interface Contact {
  name: string;
  discordId?: string;
  email: string;
  phone?: string;
  slackId?: string;
  telegramId?: string;
  type?: COMPANY_CONTACT_TYPE;
  _id?: string;
  contactLists: ContactListRelation[];
  contactListIds: string[];
  _actions: Actions;
}

type ContactListRelation = {
  id: string;
  name: string;
  type: string;
};

export enum OrderType {
  'T+0' = 'T+0',
  'T+N' = 'T+N',
  CASH = 'CASH',
}

export interface ContactList {
  _id?: string;
  name: string;
  product?: { id: string };
  settlementSummary?: {
    tlaCompanyId: string;
    apCompanyId: string;
    timezone: TimeZone;
    orderType: OrderType;
    sendAt: string;
  };
  issuer?: { id: string };
  sendAsBcc: boolean;
  contactsCount: number;
  type: CONTACT_LIST_TYPE;
  _actions: Actions;
}

export interface ContactListRequestDto {
  _id?: string;
  name: string;
  productId?: string;
  issuerId?: string;
  sendAsBcc: boolean;
  contactsCount: number;
  type: CONTACT_LIST_TYPE;
}

export interface ContactListContacts {
  external: Contact[];
  partners: PartnerContact[];
}

export enum ContactListResponseActions {
  create = 'create',
  createExternal = 'createExternal',
  viewContacts = 'viewContacts',
  viewContactsExternal = 'viewContactsExternal',
  viewContactsPartner = 'viewContactsPartner',
}
export interface ContactResponse {
  contacts: Contact[];
  _actions: Actions<ContactListResponseActions>;
  _links: Links;
}

export interface PartnerContact {
  _id?: string;
  contacts: Contact[];
  name: string;
  type: string;
  contactLists: ContactListRelation[];
}

export enum CONTACT_LIST_TYPE {
  ORDER = 'ORDER',
  PRODUCT = 'PRODUCT',
  NON_PRODUCT = 'NON_PRODUCT',
  PCF = 'PCF',
  CONSOLIDATED_PCFS = 'CONSOLIDATED_PCFS',
  SETTLEMENT_SUMMARY = 'SETTLEMENT_SUMMARY',
  BLOOMBERG_HOLDINGS_FILE = 'BLOOMBERG_HOLDINGS_FILE',
  BLOOMBERG_NAV_FILE = 'BLOOMBERG_NAV_FILE',
}

export enum CONTACT_LIST_TYPE_LABEL {
  ORDER = 'Order',
  PRODUCT = 'Product',
  NON_PRODUCT = 'Non-Product',
  PCF = 'PCF',
  CONSOLIDATED_PCFS = 'Consolidated PCFs',
  SETTLEMENT_SUMMARY = 'Settlement Summary',
  BLOOMBERG_HOLDINGS_FILE = 'Bloomberg Holdings file',
  BLOOMBERG_NAV_FILE = 'Bloomberg NAV file',
}

export const getContactType = (type: COMPANY_CONTACT_TYPE | undefined) => {
  switch (type) {
    case COMPANY_CONTACT_TYPE.IMPLICIT:
      return 'User';
    case COMPANY_CONTACT_TYPE.AFFILIATED:
      return 'Affiliated Contact';
    case COMPANY_CONTACT_TYPE.UNAFFILIATED:
      return 'External Contact';
    default:
      return type;
  }
};

export const getContactListType = (type: string) => {
  switch (type) {
    case CONTACT_LIST_TYPE.ORDER:
      return CONTACT_LIST_TYPE_LABEL.ORDER;
    case CONTACT_LIST_TYPE.PRODUCT:
      return CONTACT_LIST_TYPE_LABEL.PRODUCT;
    case CONTACT_LIST_TYPE.NON_PRODUCT:
      return CONTACT_LIST_TYPE_LABEL.NON_PRODUCT;
    case CONTACT_LIST_TYPE.PCF:
      return CONTACT_LIST_TYPE_LABEL.PCF;
    default:
      return type;
  }
};
