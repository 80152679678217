import Card from 'components/Card';
import Button from 'components/Button';
import { IModalWithData } from 'shared/Modals/types';
import { OrdersModalData } from 'shared/Modals/Orders/OrdersModal';
import Tag from 'components/Tag';
import { orderStatusTagVariants } from 'utils/constants/orders';
import { variantTypes } from 'components/Tag/Tag.types';
import titelize from 'utils/decorators/titelize';
import { ReactComponent as ArrowRightIcon } from 'assets/arrow-right.svg';
import { Deliverable, OrderStatus } from 'utils/types/orders';
import { useButtonsActions } from 'pages/Orders/Orders/hooks/useButtonsActions';
import {
  ColumnModal,
  RowModal,
  StatusChangeWrapper,
  StyledNote,
  StyledStrong,
  StyledTagsWrapper,
  StyledWarning,
} from 'shared/Modals/Orders/OrdersModal.styles';
import Input from 'components/Input';
import { chunk } from 'lodash';
import { useState } from 'react';
import { CustomModal } from 'shared/Modals/Modal';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { FeesData, FeesTable } from './components/FeesTable';
import { useOrdersActions } from 'pages/Orders/Orders/hooks/useOrdersActions/useOrdersActions';

export const OrderStatusChangeModal = ({ closeModal, data }: IModalWithData) => {
  const isToken = data?.custom?.isToken;
  const orderModalData = data?.data as OrdersModalData;
  const { readyActionWithFees } = useOrdersActions(
    orderModalData.order._id,
    orderModalData?.refreshQueryKey,
    isToken ? 'Token' : 'ETP'
  );

  const actions = useButtonsActions(
    orderModalData?.order,
    orderModalData?.refreshQueryKey,
    isToken ? 'Token' : 'ETP'
  );
  const isCashDeliveryType = orderModalData.order.deliveryType === 'CASH';
  const isSettlingOrder = OrderStatus.SETTLED === orderModalData.targetStatus;
  const isConfirmShortOrder =
    OrderStatus.CONFIRMED === orderModalData.targetStatus && orderModalData.order.product.isShort;
  const [actualDeliverables, setActualDeliverables] = useState<Deliverable[]>(
    orderModalData?.order.deliveries?.expected
      ?.filter(
        (deliverable) =>
          !isConfirmShortOrder ||
          orderModalData.order.product.leveragedConstituentsTicker?.includes(deliverable.ticker)
      )
      .map((delivery) => ({
        ...delivery,
        amount: delivery?.amount || 0,
      })) ?? []
  );

  const [fees, setFees] = useState<Omit<FeesData, 'totalFees'> | null>(null);

  const confirmAction = actions.find((action) => action.status === orderModalData.targetStatus);

  const cashReadyForward =
    isCashDeliveryType &&
    orderModalData.targetStatus === OrderStatus.READY &&
    orderModalData.order.status !== OrderStatus.SETTLED;

  const cashReadyBackward =
    isCashDeliveryType &&
    orderModalData.order.status === OrderStatus.READY &&
    orderModalData.targetStatus !== OrderStatus.SETTLED;

  const expectedDeliverables = orderModalData?.order.deliveries?.expected
    .filter(
      (deliverable) =>
        !isConfirmShortOrder ||
        orderModalData.order.product.leveragedConstituentsTicker?.includes(deliverable.ticker)
    )
    .map((delivery, index) => {
      const deliverablesLabel = isConfirmShortOrder
        ? `${delivery?.ticker} Expected Deliverable`
        : `${delivery?.ticker} Actual Deliverable`;

      const expectedDeliverablesLabel = isConfirmShortOrder
        ? `Estimated Deliverable: ${delivery?.amount}`
        : `Expected Deliverable: ${delivery?.amount}`;

      return (
        <ColumnModal cols={6}>
          <Input
            name="ticker"
            type="number"
            label={deliverablesLabel}
            value={actualDeliverables[index].amount}
            onChange={(event) => {
              setActualDeliverables((prevState) => {
                const deliverables = [...prevState];
                deliverables[index] = {
                  ...deliverables[index],
                  amount: event.target.value === '' ? undefined : Number(event.target.value),
                };
                return deliverables;
              });
            }}
          />
          <StyledNote>{expectedDeliverablesLabel}</StyledNote>
        </ColumnModal>
      );
    });

  const shouldSetActualDeliverables =
    expectedDeliverables &&
    (isSettlingOrder || isConfirmShortOrder) &&
    expectedDeliverables?.length > 0 &&
    !isCashDeliveryType;

  const shouldShowUpdateFeesTable =
    orderModalData.targetStatus === OrderStatus.READY && isCashDeliveryType && !isToken;

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button data-qa-id="cancelButton" variant="secondary" fullWidth onClick={closeModal}>
        Cancel
      </Button>
      <Button
        data-qa-id="addButton"
        type="submit"
        fullWidth
        onClick={() => {
          if ((isSettlingOrder && !isCashDeliveryType) || isConfirmShortOrder) {
            confirmAction?.callback(actualDeliverables);
          } else if (shouldShowUpdateFeesTable) {
            readyActionWithFees({
              adminFee: Number(fees?.adminFee),
              executionFee: Number(fees?.executionFee),
              executionFeeBps: Number(fees?.executionFeeBps),
            });
          } else {
            confirmAction?.callback();
          }
          closeModal();
        }}
      >
        Confirm
      </Button>
    </MuiStyledModalFooterButtons>
  );
  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        footer={Footer}
        label="Orders"
        title={titelize(orderModalData.targetStatus)}
        onClose={closeModal}
      >
        <StatusChangeWrapper>
          <div>
            Moving <StyledStrong>{orderModalData.order?.externalId}</StyledStrong> from:
          </div>
          <StyledTagsWrapper>
            <Tag
              label={titelize(orderModalData.order.status)}
              variant={orderStatusTagVariants[orderModalData.order.status] as variantTypes}
            />
            <ArrowRightIcon />
            <Tag
              label={titelize(orderModalData.targetStatus)}
              variant={orderStatusTagVariants[orderModalData.targetStatus] as variantTypes}
            />
          </StyledTagsWrapper>
        </StatusChangeWrapper>
        {shouldSetActualDeliverables && (
          <>
            <div>
              Please confirm the following{' '}
              {expectedDeliverables && expectedDeliverables?.length > 1 ? 'values' : 'value'}:
            </div>
            {chunk(expectedDeliverables, 2).map(([deliveryOne, deliveryTwo], index) => (
              <RowModal key={`expectedDeliverables-${index}`}>
                {deliveryOne}
                {deliveryTwo}
              </RowModal>
            ))}
          </>
        )}
        {shouldShowUpdateFeesTable && (
          <FeesTable onDataChange={setFees} orderData={orderModalData.order} />
        )}
        <StyledWarning>
          {cashReadyForward && 'This will trigger scheduling trades.'}
          {cashReadyBackward &&
            'This will cancel pending trades or request inverse trades if already executed and slippage may be incurred.'}
        </StyledWarning>
      </Card>
    </CustomModal>
  );
};
