// documentation: https://mui.com/material-ui/react-tooltip/
import { Box, styled } from '@mui/material';
import MaterialTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const TooltipContent = styled(Box)<{ inline?: boolean }>`
  width: fit-content;
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
`;

const Tooltip = styled(
  ({
    arrow = true,
    className,
    children,
    inline = false,
    wide = false,
    ...props
  }: TooltipProps & { inline?: boolean; wide?: boolean; bgColor?: string }) => (
    <MaterialTooltip {...props} arrow={arrow} classes={{ popper: className }}>
      <TooltipContent inline={inline}>{children}</TooltipContent>
    </MaterialTooltip>
  )
)(({ bgColor, color }) => {
  const resolvedBgColor = bgColor === 'black' ? '#101012' : bgColor;
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: resolvedBgColor ?? 'inherit',
      color: color ?? 'inherit',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: resolvedBgColor ?? 'inherit',
    },
  };
});

export default Tooltip;
