/* eslint-disable complexity */
import Table from 'components/Table';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { Order, OrderStatus } from 'utils/types/orders';
import { formatToCurrency } from 'utils/formatting';
import Box from '@mui/material/Box';
import { TableBody, Typography } from '@mui/material';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { MuiStyledTableCell } from 'components/Table/TableCell';

interface DealInformationProps {
  order?: Order;
}

const TransactionSummary = ({ order }: DealInformationProps) => {
  const showTable = order?.status == OrderStatus.SETTLED || order?.status == OrderStatus.READY;
  const adminFee = order?.tradesAdjustedAdminFee ?? order?.tradesAdminFee;
  const executionFee = order?.tradesAdjustedExecutionFee ?? order?.tradesExecutionFee;
  const totalFees =
    adminFee || executionFee ? Number(adminFee) + Number(executionFee) : order?.tradesTotalFees;
  const baseCurrency = order?.product?.currency || 'USD';

  return showTable ? (
    <Box component="section">
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Transaction Summary</Typography>
      </MuiStyledSectionHeader>
      <Table noPadding={true}>
        <TableBody>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Product Base Currency</MuiStyledTableCell>
            <MuiStyledTableCell align="right">{baseCurrency}</MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Total Actual Deliverable</MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {order?.settlement && Boolean(order?.tradesTotal)
                ? formatToCurrency(order?.tradesTotal, baseCurrency)
                : '-'}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>
              {order?.type === 'REDEMPTION' && order.deliveryType === 'CASH'
                ? 'Estimated Deliverable'
                : 'Estimated Settlement Amount'}
            </MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {Boolean(order?.deliveries?.expectedTotalCash)
                ? formatToCurrency(order?.deliveries?.expectedTotalCash, baseCurrency)
                : '-'}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Total Fees</MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {Boolean(totalFees) ? formatToCurrency(totalFees, baseCurrency) : '-'}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell sx={{ '&.MuiTableCell-root': { paddingLeft: '48px' } }}>
              Admin Fee
            </MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {Boolean(adminFee) ? formatToCurrency(adminFee, baseCurrency) : '-'}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell sx={{ '&.MuiTableCell-root': { paddingLeft: '48px' } }}>
              Execution Fee
            </MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {Boolean(executionFee) ? formatToCurrency(executionFee, baseCurrency) : '-'}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Total Slippage</MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {Boolean(order?.tradesTotalSlippageFromTotalAndPriceProvider)
                ? formatToCurrency(
                    order?.tradesTotalSlippageFromTotalAndPriceProvider,
                    baseCurrency
                  )
                : '-'}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
          <MuiStyledTableRow>
            <MuiStyledTableCell>Amount to Settle (Base)</MuiStyledTableCell>
            <MuiStyledTableCell align="right">
              {Boolean(order?.tradesTotalWithTotalFee)
                ? formatToCurrency(order?.tradesTotalWithTotalFee, baseCurrency)
                : '-'}
            </MuiStyledTableCell>
          </MuiStyledTableRow>
        </TableBody>
      </Table>
    </Box>
  ) : null;
};

export default TransactionSummary;
