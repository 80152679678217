import { Order, ProductType } from 'utils/types';
import { StyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { ActualDeliverablesTable } from './ActualDeliverablesTable/ActualDeliverablesTable';
import ActualDeliverablesList from './ActualDeliverablesList/index';
import { ORDER_IN_KIND } from 'utils/constants';
import { Typography } from '@mui/material';

interface ActualDeliverablesProps {
  productType: ProductType;
  order?: Order;
}

const ActualDeliverables = ({ productType, order }: ActualDeliverablesProps) => {
  return order?.trades?.length || order?.deliveries?.actual?.length ? (
    <section>
      <StyledSectionHeader>
        <Typography variant="subheadingMedium">Actual Deliverables</Typography>
      </StyledSectionHeader>
      {productType === 'ETP' &&
        (order?.deliveryType === ORDER_IN_KIND || !order?._id ? (
          <ActualDeliverablesList order={order} />
        ) : (
          <ActualDeliverablesTable productType={productType} order={order} />
        ))}
      {productType === 'Token' && (
        <ActualDeliverablesTable productType={productType} order={order} />
      )}
    </section>
  ) : null;
};

export default ActualDeliverables;
