import { Stack, TableBody, TableHead, Typography } from '@mui/material';
import { ReactComponent as ExternalLinkIcon } from 'assets/external-link.svg';
import Table, { TableCell, TableRow } from 'components/Table';
import TableHeader from 'shared/Tables/Table/TableHeader';
import { formatToCurrency } from 'utils/formatting';
import { Deliverable, Order, OrderStatus, OrderTrade } from 'utils/types/orders';
import { ProductType } from 'utils/types/product';

interface ActualDeliverablesSettlementTableProps {
  productType: ProductType;
  order?: Order;
}

export const ActualDeliverablesTable = ({
  productType,
  order,
}: ActualDeliverablesSettlementTableProps) => {
  const showCostBreakDown =
    order?.status == OrderStatus.SETTLED || order?.status == OrderStatus.READY;

  const ActualDeliverablesTableColumnsConfig = [
    { label: 'Ticker', propName: 'ticker', propType: 'string', sort: false },
    { label: 'Quantity', propName: 'amount', propType: 'string', sort: false },
    { label: 'Value (base ccy)', propName: 'value', propType: 'string', sort: false },
    { label: 'Trade Price', propName: 'price', propType: 'string', sort: false },
    { label: 'FX Rate base to delivery', propName: 'fxRateUSD', propType: 'string', sort: false },
    {
      label: 'Actual deliverables',
      propName: 'actualDeliverables',
      propType: 'string',
      sort: false,
    },
  ];

  const ActualDeliverables = (
    <Table noPadding>
      <TableHeader columns={ActualDeliverablesTableColumnsConfig} showActionsCell={false} />
      <TableBody>
        {order &&
          order?.trades?.map((trade: OrderTrade) => (
            <TableRow key={trade._id}>
              <TableCell key="ticker">{trade?.baseCurrency}</TableCell>
              <TableCell key="coinQty">{trade.quantity}</TableCell>
              <TableCell key="value">
                {formatToCurrency(trade.total, String(trade.quoteCurrency))}{' '}
              </TableCell>
              <TableCell key="price">
                {formatToCurrency(trade.price, String(trade.quoteCurrency))}
              </TableCell>
              <TableCell key="fxRateToUSD">{order?.tradesDeliveryCurrencyFxRate}</TableCell>
              <TableCell key="actualDeliverables">
                {formatToCurrency(trade?.totalInDeliveryCurrency, String(trade.quoteCurrency))}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );

  const ActualDeliverablesToken = (
    <Table noPadding>
      <TableHead>
        <TableRow>
          <TableCell>Ticker</TableCell>
          <TableCell>Transaction</TableCell>
          <TableCell align="right">Quantity</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {order?.deliveries?.actual?.map((deliverable: Deliverable, index: number) => (
          <TableRow key={`actual-deliverable-${index}`}>
            <TableCell>{deliverable?.ticker}</TableCell>
            <TableCell align="left">
              {deliverable?.transactionIds?.length ? (
                <Stack direction="column" spacing={1}>
                  {deliverable.transactionIds.map((transactionId, idx) =>
                    (deliverable.transactionUrls ?? [])[idx]?.includes(transactionId) ? (
                      <Stack direction={'row'} gap={'1'} alignItems={'center'}>
                        <a
                          href={(deliverable.transactionUrls ?? [])[idx]}
                          key={idx}
                          rel="noreferrer"
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                        >
                          <Typography variant="bodySmall">{transactionId}</Typography>
                        </a>
                        <ExternalLinkIcon style={{ minWidth: '25px' }} />
                      </Stack>
                    ) : (
                      <Typography variant="bodySmall" key={idx}>
                        {transactionId}
                      </Typography>
                    )
                  )}
                </Stack>
              ) : (
                <Typography variant="bodySmall">-</Typography>
              )}
            </TableCell>
            <TableCell align="right">{deliverable?.amount}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const CostBreakdownTable = (
    <Table noPadding>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>Cost breakdown (base ccy)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Admin Fee</TableCell>
          <TableCell align="right">
            {Boolean(order?.tradesAdminFee || order?.tradesAdjustedAdminFee)
              ? formatToCurrency(
                  order?.tradesAdminFee || order?.tradesAdjustedAdminFee,
                  order?.deliveries?.expectedTotalCurrency
                )
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Execution Fee</TableCell>
          <TableCell align="right">
            {Boolean(order?.tradesExecutionFee)
              ? formatToCurrency(
                  order?.tradesExecutionFee,
                  order?.deliveries?.expectedTotalCurrency
                )
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Slippage</TableCell>
          <TableCell align="right">
            {Boolean(order?.tradesTotalSlippageFromTotalAndPriceProvider)
              ? formatToCurrency(
                  order?.tradesTotalSlippageFromTotalAndPriceProvider,
                  order?.deliveries?.expectedTotalCurrency
                )
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Total cost of trade</TableCell>
          <TableCell align="right">
            {Boolean(order?.tradesTotalFees)
              ? formatToCurrency(order?.tradesTotalFees, order?.deliveries?.expectedTotalCurrency)
              : '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  const TotalAmountToSettleTable = (
    <Table noPadding>
      <TableHead>
        <TableRow>
          <TableCell>Total amount to settle (delivery ccy)</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Order Amount</TableCell>
          <TableCell align="right">
            {Boolean(order?.tradesTotalFromPriceProviderInDeliveryCurrency)
              ? formatToCurrency(
                  order?.tradesTotalFromPriceProviderInDeliveryCurrency,
                  order?.deliveryCurrency
                )
              : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cost of trade</TableCell>
          <TableCell align="right">
            {Boolean(order?.tradesTotalFeesInDeliveryCurrency)
              ? formatToCurrency(order?.tradesTotalFeesInDeliveryCurrency, order?.deliveryCurrency)
              : '-'}
          </TableCell>
        </TableRow>{' '}
        <TableRow>
          <TableCell>Total amount to settle</TableCell>
          <TableCell align="right">
            {Boolean(order?.tradesTotalWithTotalFeeInDeliveryCurrency)
              ? formatToCurrency(
                  order?.tradesTotalWithTotalFeeInDeliveryCurrency,
                  order?.deliveryCurrency
                )
              : '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <>
      {productType === 'ETP' && (
        <Stack gap={2}>
          {ActualDeliverables}
          <br></br>
          {showCostBreakDown && (
            <>
              {CostBreakdownTable}
              <br></br>
              {TotalAmountToSettleTable}
            </>
          )}
        </Stack>
      )}
      {productType === 'Token' && order?.deliveries?.actual?.length && ActualDeliverablesToken}
    </>
  );
};
