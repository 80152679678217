import { Chain } from 'utils/types/product';
import { ReactComponent as ExternalLinkIcon } from 'assets/external-link.svg';

enum Network {
  LOCALHOST = 'localhost',
  ETHEREUM_HOLESKY = 'holesky',
  ETHEREUM_MAINNET = 'mainnet',
  SOLANA_DEVNET = 'devnet',
  SOLANA_TESTNET = 'testnet',
  SOLANA_MAINNET_BETA = 'mainnet-beta',
}

enum EXPLORER {
  ETHERSCAN = 'ETHERSCAN',
  SOLSCAN = 'SOLSCAN',
}

const EXPLORER_MAP: Record<string, { address: string; transaction: string }> = {
  [EXPLORER.ETHERSCAN]: {
    address: 'https://etherscan.io/address/',
    transaction: 'https://etherscan.io/tx/',
  },
  [EXPLORER.SOLSCAN]: {
    address: 'https://solscan.io/address/',
    transaction: 'https://solscan.io/tx/',
  },
};

interface AddressLinkProps {
  destinationChain: Chain;
  data: string;
  type: 'address' | 'transaction';
  align?: 'left' | 'right';
  gap?: boolean;
}

function getExplorerLink({
  chain,
  data,
  type,
}: {
  chain: Chain;
  data: string;
  type: 'address' | 'transaction';
}): string {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const explorer = chain === Chain.ETHEREUM ? EXPLORER.ETHERSCAN : EXPLORER.SOLSCAN;

  let network;

  // set network for ethereum chain based on env
  if (chain === Chain.ETHEREUM) {
    if (environment === 'prod') {
      network = Network.ETHEREUM_MAINNET;
    } else {
      network = Network.ETHEREUM_HOLESKY;
    }

    let link = `${EXPLORER_MAP[explorer][type]}${data}`;

    if (Network.ETHEREUM_HOLESKY === network) {
      link = link.replace('etherscan', `${network.toLowerCase()}.etherscan`);
    }

    return link;
  }

  if (chain === Chain.SOLANA) {
    if (environment === 'prod') {
      network = Network.SOLANA_MAINNET_BETA;
    } else {
      network = Network.SOLANA_DEVNET;
    }

    return `${EXPLORER_MAP[explorer][type]}${data}${
      network !== Network.SOLANA_MAINNET_BETA ? '?cluster=' + network : ''
    }`;
  }

  return '';
}

function ExplorerLink({
  destinationChain = Chain.ETHEREUM,
  data,
  type,
  align,
  gap = true,
}: AddressLinkProps) {
  const href = getExplorerLink({ chain: destinationChain, data, type });

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: `${align === 'left' ? 'flex-start' : 'flex-end'}`,
        gap: gap ? '0.5em' : '0em',
      }}
    >
      {data ? (
        <>
          <a
            style={{ textDecoration: href ? 'underline' : '' }}
            href={href}
            target={href ? '_blank' : ''}
            rel={href ? 'noreferrer' : ''}
          >
            {data}
          </a>
          {href && <ExternalLinkIcon style={{ minWidth: '25px' }} />}
        </>
      ) : (
        '-'
      )}
    </div>
  );
}

export default ExplorerLink;
