import { Box, styled as MuiStyled } from '@mui/material';

export const StyledDoubleTableWrapper = MuiStyled(Box)(({ theme }) => ({
  marginInline: theme.spacers['spacing-08'],
  marginBottom: theme.spacers['spacing-32'],
}));

export const StyledTableHeaderWrapper = MuiStyled(Box)`
  width: 100%;
  display: flex;
  vertical-align: center;
  align-items: center;
  justify-content: space-between;
`;
