import { Order } from 'utils/types/orders';
import getOpenTime from './getOpenTime';
import getCloseTime from './getCloseTime';

export const getExchangeWorkingHoursInformation = (orderDetails?: Order) => {
  const product = orderDetails?.product;
  const deliveryType = orderDetails?.deliveryType;

  const openTime = getOpenTime(product);
  const closeTime = getCloseTime(
    product?.cutoffs,
    product?.primaryExchange.calendar.timezone,
    deliveryType
  );

  return {
    openTime: openTime,
    closeTime: closeTime,
    isClosed: !product || !product.isMarketOpen,
  };
};
