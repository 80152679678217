import { ReactComponent as ArrowDown } from 'assets/chevron-down.svg';
import { Chip, styled as MuiStyled } from '@mui/material';

interface ChipProps {
  expandable: 'false' | 'true';
}

export const StyledArrowDown = MuiStyled(ArrowDown)`
  margin: auto 0;
  width: ${({ theme }) => theme.spacers['spacing-16']};
  height: ${({ theme }) => theme.spacers['spacing-16']};
  padding-right: ${({ theme }) => theme.spacers['spacing-08']};
`;

export const MuiChip = MuiStyled(Chip)<ChipProps>(({ theme, expandable }) => ({
  '.MuiChip-label': {
    color: theme.palette.common.white,
    paddingRight: expandable === 'true' ? 0 : theme.spacers['spacing-12'],
  },
  '& .MuiChip-icon': {
    color: theme.palette.common.white,
  },
  svg: {
    fill: theme.palette.common.white,
  },
  ...(expandable === 'true' && {
    '&.MuiChip-root': {
      flexDirection: 'row-reverse',
      paddingRight: theme.spacers['spacing-08'],

      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
  // Add condition for variant 06 (background color is gray and font color is white - not readable)
  '&.MuiChip-colorDefault': {
    '.MuiChip-label': {
      color: theme.palette.text.primary, // Change font color to primary text color
    },
  },
}));
