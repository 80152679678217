import Card from '@mui/material/Card';
import { Box, CardContent, CardActions } from '@mui/material';
import { ReactNode } from 'react';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { OnCloseContainer } from './NewCard.styles';

interface CardProps extends React.PropsWithChildren {
  classes?: string;
  header?: ReactNode;
  headerBorder?: boolean;
  body?: ReactNode;
  footer?: ReactNode;
  maxCardHeight?: string;
  maxBodyHeight?: string;
  footerBorder?: boolean;
  onClose?: () => void;
  sx?: {
    [key: string]: any;
  };
  sxBody?: {
    [key: string]: any;
  };
  noPadding?: Boolean;
}

export const NewCard = ({
  classes,
  header,
  headerBorder = false,
  body,
  footer,
  maxCardHeight,
  maxBodyHeight,
  footerBorder = false, // not needed when wrapping tables so default is false
  onClose,
  noPadding = false,
  sx,
  sxBody,
}: CardProps) => {
  return (
    <Card
      classes={classes}
      sx={{
        maxHeight: maxCardHeight,
        padding: !noPadding ? '24px' : '0',
        ...sx,
      }}
    >
      {onClose && (
        <OnCloseContainer>
          <CloseIcon />
        </OnCloseContainer>
      )}
      {header && (
        <Box
          sx={{ borderBottom: headerBorder ? '1.25px solid #98ABB833' : 'none' }}
          minHeight={'40px'}
        >
          {header}
        </Box>
      )}
      {body && <CardContent sx={{ maxHeight: maxBodyHeight, ...sxBody }}>{body}</CardContent>}
      {footer && (
        <CardActions sx={{ borderTop: footerBorder ? '1.25px solid #98ABB833' : 'none' }}>
          {footer}
        </CardActions>
      )}
    </Card>
  );
};
