import { alpha } from '@mui/material';
import { blue, gold, green, grey, neutral, orange, red, yellow } from 'theme/colors/baseColors';

export const primary = {
  lighter: yellow[20],
  light: yellow[40],
  main: yellow[60],
  dark: yellow[70],
  darker: yellow[80],
  contrastText: neutral[0],
};

export const secondary = {
  lighter: '#4D4D4D',
  light: '#4D4D4D',
  main: '#1A1A1A',
  dark: '#333333',
  darker: '#4D4D4D',
  contrastText: neutral[0],
};

export const cellRange = {
  main: '#4D4D4D',
  success: '#28A445',
  error: red[50],
};

export const tertiary = {
  lighter: '#F7F7F7',
  light: '#F7F7F7',
  main: '#F7F7F7',
  dark: '#E6E6E6',
  darker: '#CCCCCC',
  contrastText: '#2C2C2C',
};

export const support = {
  lighter: gold[20],
  light: gold[40],
  main: gold[50],
  dark: gold[60],
  darker: gold[70],
  contrastText: neutral[0],
};

export const inverse = {
  lighter: neutral[0],
  light: neutral[0],
  main: neutral[0],
  dark: '#E6E6E6',
  darker: '#CCCCCC',
  contrastText: '#000000',
};

export const danger = {
  lighter: '#FFEEEE',
  light: '#FF6666',
  main: '#D2222C',
  dark: '#990000',
  darker: '#CCCCCC',
  contrastText: neutral[0],
};

export const info = {
  lighter: '#F1F7FF',
  light: '#61F3F3',
  main: '#00B8D9',
  dark: '#006C9C',
  darker: '#003768',
  contrastText: neutral[0],
};

export const success = {
  lighter: '#F1FFF5',
  light: '#77ED8B',
  main: '#22C55E',
  dark: '#118D57',
  darker: '#065E49',
  contrastText: neutral[0],
};

export const warning = {
  lighter: orange[10],
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#B76E00',
  darker: '#7A4100',
  contrastText: grey[800],
};

export const error = {
  lighter: '#FFEEEE',
  light: '#FFAC82',
  main: '#FF5630',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: neutral[0],
};

export const interactive = {
  lighter: 'rgba(255,194,163,0.1)',
  light: '#FFAA80',
  main: '#FF7A37',
  dark: orange[70],
  darker: orange[80],
  contrastText: neutral[0],
};

export const common = {
  black: '#000000',
  white: neutral[0],
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

export const notificationsColorRange = {
  color: common.white,
  backgroundColor: {
    primary: grey[10],
    info: blue[10],
    success: green[10],
    warning: yellow[10],
    error: red[10],
  },
  border: {
    secondary: {
      info: blue[500],
      success: green[500],
      warning: orange[500],
      error: danger.light,
      link: blue[400],
    },
    main: {
      info: blue[70],
      success: cellRange.success,
      warning: orange[700],
      error: red[500],
      link: blue[400],
    },
  },
};
