import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { TableBody } from '@mui/material';
import TableCell from 'components/Table/TableCell';
import TableRow from 'components/Table/TableRow';
import { useEffect, useState } from 'react';
import { Order } from 'utils/types';
import InputComponent from 'components/Input';
import BigNumber from 'bignumber.js';

export interface FeesData {
  adminFee: string | number;
  executionFee: string | number;
  executionFeeBps: string | number;
  totalFees: string | number;
}

const getExecutionFee = (executionFeeBPS: number, totalFees: number) => {
  const executionFeesBigNumber = new BigNumber(executionFeeBPS);
  const totalFeesBigNumber = new BigNumber(totalFees);
  return executionFeesBigNumber.multipliedBy(totalFeesBigNumber).multipliedBy(0.0001).toNumber();
};

export const FeesTable = ({
  orderData,
  onDataChange,
}: {
  orderData: Order;
  onDataChange: (val: Omit<FeesData, 'totalFees'>) => void;
}) => {
  const initialTotalFees =
    (orderData.tradesExecutionFee ? Number(orderData.tradesExecutionFee) : 0) +
    (orderData.tradesAdminFee ? Number(orderData.tradesAdminFee) : 0);
  const [fee, setFee] = useState<{
    adminFee: string | number;
    executionFeeBps: string | number;
    totalFees: string | number;
    executionFee: string | number;
  }>({
    adminFee: orderData.tradesAdminFee ? new BigNumber(orderData.tradesAdminFee).toNumber() : '',
    executionFeeBps: orderData.tradesExecutionFeeBps
      ? new BigNumber(orderData.tradesExecutionFeeBps).toNumber()
      : '',
    totalFees: initialTotalFees ?? '',
    executionFee: getExecutionFee(
      Number(orderData?.tradesExecutionFeeBps),
      orderData?.tradesTotal!
    ),
  });

  const UpdateFeesTableColumns = [
    {
      label: 'Fee Summary',
      propName: '',
      propType: 'string',
    },
    { label: '', propName: '', propType: '' },
  ];

  useEffect(() => onDataChange(fee), [fee]);

  const feesTableRow = (
    label: string,
    value: number | string,
    setValue: (val: string | number) => void
  ) => {
    const handleChange = (event: { target: { value: string } }) => {
      const regex = /^[+-.]$/;
      if (!regex.test(event.target.value as string)) {
        setValue(Number(event.target.value));
      }
    };

    return (
      <TableRow sx={{ height: '32px' }}>
        <TableCell
          sx={{
            width: '50%',
            '&.MuiTableCell-root': { height: '32px' },
          }}
        >
          {label}
        </TableCell>
        <TableCell sx={{ '&.MuiTableCell-root': { padding: 0, height: '32px' }, borderBottom: 0 }}>
          <InputComponent
            type="number"
            value={value}
            disableArrows
            onChange={handleChange}
            hideBorderRadius
            size="small"
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table>
      <TableHeaderWithMultiSort columns={UpdateFeesTableColumns} />
      <TableBody>
        {feesTableRow('Admin Fee ($)', fee.adminFee, (val: string | number) =>
          setFee({
            adminFee: val,
            executionFeeBps: fee.executionFeeBps,
            totalFees: Number(val) + Number(fee.executionFee),
            executionFee: fee.executionFee,
          })
        )}
        {feesTableRow('Execution Fee (bps)', fee.executionFeeBps, (val: string | number) => {
          const executionFee = getExecutionFee(Number(val), orderData?.tradesTotal!);
          setFee({
            adminFee: fee.adminFee,
            executionFeeBps: val,
            executionFee: executionFee,
            totalFees: Number(executionFee) + Number(fee.adminFee),
          });
        })}
        <TableRow sx={{ height: '32px' }}>
          <TableCell
            sx={{
              width: '50%',
              '&.MuiTableCell-root': { height: '32px' },
            }}
          >
            Total fees
          </TableCell>
          <TableCell
            data-qa-id="onyx-fund-accountant-balance"
            sx={{ '&.MuiTableCell-root': { padding: 0, height: '32px' } }}
          >
            {`$${Number(fee.totalFees)}`}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
