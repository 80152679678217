import Table from 'components/Table';
import TableHeaderWithMultiSort from 'shared/Tables/Table/TableHeaderWithMultiSort';
import { Deliverable, Order } from 'utils/types';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { formatToCurrency } from 'utils/formatting';
import { TableBody } from '@mui/material';
import BigNumber from 'bignumber.js';

export const ExpectedDeliverablesInfoTableColumnsConfig = [
  { label: 'Ticker', propName: 'ticker', propType: 'string', width: '38%' },
  { label: 'Market Value', propName: 'marketValue', propType: 'string', width: '38%' },
  { label: 'Quantity', propName: 'quantity', propType: 'string', width: '16%' },
];

interface ExpectedDeliverablesProps {
  order?: Order;
}

const ZERO_UNITS = 0;

export const ExpectedDeliverablesInfoList = ({ order }: ExpectedDeliverablesProps) => {
  const deliverables = order?.deliveries?.expected;
  const totalCoins = deliverables?.reduce((total, deliverable) => {
    return total + (deliverable.amount ?? 0);
  }, 0);
  const totalCurrency = order?.deliveries?.expectedTotalCash;

  return (
    <Table noPadding={true}>
      <TableHeaderWithMultiSort columns={ExpectedDeliverablesInfoTableColumnsConfig} />
      <TableBody>
        {order &&
          deliverables?.map((deliverable: Deliverable) => (
            <MuiStyledTableRow key={`expected-deliverable-${deliverable.ticker}`}>
              {/* Ticker */}
              <MuiStyledTableCell>{deliverable.ticker}</MuiStyledTableCell>
              {/* Market Value */}
              <MuiStyledTableCell>
                {deliverable.totalInCurrency
                  ? formatToCurrency(
                      BigNumber(deliverable?.totalInCurrency || ZERO_UNITS).toNumber(),
                      order?.deliveries?.expectedTotalCurrency ?? 'USD'
                    )
                  : '-'}
              </MuiStyledTableCell>
              {/* Quantity */}
              <MuiStyledTableCell>
                {deliverable?.amount
                  ? BigNumber(deliverable?.amount ?? ZERO_UNITS).toNumber()
                  : '-'}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          ))}
        <MuiStyledTableRow>
          <MuiStyledTableCell>Total</MuiStyledTableCell>
          <MuiStyledTableCell>
            {totalCurrency
              ? formatToCurrency(totalCurrency, order?.deliveries?.expectedTotalCurrency ?? 'USD')
              : '-'}
          </MuiStyledTableCell>
          <MuiStyledTableCell>{totalCoins ? totalCoins : '-'}</MuiStyledTableCell>
        </MuiStyledTableRow>
      </TableBody>
    </Table>
  );
};
