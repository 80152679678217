import Button from 'components/Button';
import Card from 'components/Card';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import Select from 'components/Select';
import { CompanyCustodianWallet, CustodianWalletType } from 'utils/types/wallets';
import { CustomModal } from 'shared/Modals/Modal';
import { DefaultValues } from 'react-hook-form';
import { IModalWithData } from 'shared/Modals/types';
import { Instrument, ProductStatus, Token } from 'utils/types/product';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { WalletModalData } from 'shared/Modals/Wallets/WalletsModal';
import { createCustodianWalletSchemaUrl } from 'components/Form/formSchemas';
import { getProductOptions } from './utils';
import { useInstrumentCustodiansQuery } from 'pages/Instruments/components/Form/PartnerDetails/components/CustodianInfoStep/hooks/useInstrumentCustodians';
import { useInstrumentsQuery } from 'hooks/useInstrumentsQuery';
import { useMemo, useState } from 'react';
import { useGetTokensQuery } from 'hooks/useTokens';
import { useUpdateCustodianWalletModalActions } from './hooks/useUpdateCustodianWalletModalActions';

export const UpdateCustodianWalletModal = ({
  onCloseModalAction,
  data,
  closeModal,
}: IModalWithData) => {
  const isToken = data.custom?.isToken;

  const { companyData, data: walletModalData } = data!;
  const wallet = (walletModalData as WalletModalData)?.wallet as CompanyCustodianWallet;
  const custodianWalletType =
    (walletModalData as WalletModalData)?.custodianWalletType ??
    (wallet as CompanyCustodianWallet)?.type ??
    CustodianWalletType.CONSTITUENT;
  const [selectedProductId, seSelectedProductId] = useState<string | undefined>(wallet?.product);

  const {
    data: ETPsResponse,
    isLoading: loadingETPs,
    isFetching: fetchingETPs,
  } = useInstrumentsQuery(companyData?.id, [ProductStatus.ACTIVE], !isToken);

  const {
    data: tokens,
    isLoading: loadingTokens,
    isFetching: fetchingTokens,
  } = useGetTokensQuery({ status: [ProductStatus.ACTIVE] }, companyData?.id, Boolean(isToken));

  const {
    isLoading: loadingCustodians,
    isFetching: fetchingCustodians,
    data: custodians,
  } = useInstrumentCustodiansQuery(selectedProductId ?? '');

  const custodianOptions = useMemo(
    () => (custodians ? getProductOptions(custodians) : []),
    [custodians]
  );

  const products = isToken ? (tokens as Token[]) : ETPsResponse?.data;
  const { handleUpdate, isUpdating, productsOptions, constituentOptions, isNewWallet } =
    useUpdateCustodianWalletModalActions(
      products,
      selectedProductId,
      closeModal,
      onCloseModalAction,
      data
    );

  const actionButtonText = isNewWallet ? 'Add Wallet' : 'Update Wallet';

  const handleFormUpdate = async (values: DefaultValues<CompanyCustodianWallet>) => {
    if (values?.product && values?.product !== selectedProductId) {
      seSelectedProductId(values?.product);
    }
  };

  const allowedDeliveryCurrencies = useMemo(
    () =>
      products &&
      (products as Instrument[]).find((product) => product._id === selectedProductId)
        ?.allowedDeliveryCurrencies,
    [products, selectedProductId]
  );

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        data-qa-id="cancelButton"
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
      >
        Cancel
      </Button>
      <Button data-qa-id="addButton" fullWidth isLoading={isUpdating} type="submit">
        {actionButtonText}
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Form
        schemaUrl={createCustodianWalletSchemaUrl}
        onSubmit={handleUpdate}
        onChange={handleFormUpdate}
        initialValues={{ ...wallet, type: custodianWalletType } ?? {}}
        loading={
          loadingCustodians ||
          fetchingCustodians ||
          loadingETPs ||
          fetchingETPs ||
          loadingTokens ||
          fetchingTokens
        }
      >
        <Card title={actionButtonText} label={'Wallet'} footer={Footer} onClose={closeModal}>
          <Select
            data-qa-id="chain"
            data-qa-options-id="chain"
            name="chain"
            disabled={!isNewWallet}
          />
          <Select
            name="product"
            data-qa-id="product"
            data-qa-options-id="product"
            disabled={!isNewWallet}
            options={productsOptions}
          />
          {custodianWalletType === CustodianWalletType.CONSTITUENT && (
            <Select
              name="constituentId"
              data-qa-id="constituentId"
              data-qa-options-id="constituentId"
              disabled={!selectedProductId}
              options={constituentOptions}
            />
          )}
          {custodianWalletType === CustodianWalletType.DELIVERY && (
            <Select
              name="deliveryAssetId"
              data-qa-id="deliveryAssetId"
              data-qa-options-id="deliveryAssetId"
              disabled={!selectedProductId}
              options={allowedDeliveryCurrencies?.map((currency) => ({
                label: currency.ticker,
                value: currency.assetId,
              }))}
            />
          )}
          <Input name="address" disabled={!isNewWallet} data-qa-id="address" withClipboard />
          <Input name="description" data-qa-id="description" />
          <Select
            name="custodianAccount"
            data-qa-id="custodianAccount"
            data-qa-options-id="custodianAccount"
            disabled={!selectedProductId}
            options={custodianOptions}
          />
          <Input name="idAtCustodian" data-qa-id="idAtCustodian" />
        </Card>
      </Form>
    </CustomModal>
  );
};
