import Table from 'components/Table';
import Tag from 'components/Tag';
import replace from 'lodash/replace';
import titelize from 'utils/decorators/titelize';
import { MuiStyledSectionHeader } from 'pages/Orders/Orders/Order/components/OrderDetails/OrderDetails.styles';
import { MuiStyledTableCell } from 'components/Table/TableCell';
import { MuiStyledTableRow } from 'components/Table/TableRow';
import { NavOptionsTitles } from 'pages/Orders/Orders/types';
import { Order, OrderFlow, User, ProductType } from 'utils/types';
import { TableBody, Typography } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/formatting';
import { orderStatusTagVariants } from 'utils/constants/orders';
import { renderOrderValue } from 'shared/Tables/table.utils';
import { variantTypes } from 'components/Tag/Tag.types';
import ExplorerLink from 'components/ExplorerLink';

export interface DealInformationProps {
  productType: ProductType;
  order?: Order;
  user: User | null;
  orderFlow: OrderFlow;
}

const ZERO_UNITS = 0;

const DealInformation = ({ productType, order, user, orderFlow }: DealInformationProps) => {
  const showExistingOrderDetails = orderFlow === 'confirm';
  const baseCurrency = order?.product?.currency || 'USD';

  return (
    <section>
      <MuiStyledSectionHeader>
        <Typography variant="subheadingMedium">Deal Information</Typography>
      </MuiStyledSectionHeader>
      {productType == 'ETP' && (
        <Table noPadding={true}>
          <TableBody>
            {showExistingOrderDetails && (
              <MuiStyledTableRow>
                <MuiStyledTableCell>Order Number</MuiStyledTableCell>
                <MuiStyledTableCell align="right">{order?.externalId}</MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
            {showExistingOrderDetails && (
              <MuiStyledTableRow data-html2canvas-ignore>
                <MuiStyledTableCell>State</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order && (
                    <Tag
                      label={replace(titelize(order.status), 'Ap ', 'AP ')}
                      variant={orderStatusTagVariants[order.status] as variantTypes}
                    />
                  )}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
            <MuiStyledTableRow>
              <MuiStyledTableCell>Deal Date</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order?.dealDate), order?.dealDate)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Settlement Date</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {order?.settlement &&
                  renderOrderValue(Boolean(order?.settlement.date), order?.settlement.date)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            {showExistingOrderDetails && (
              <MuiStyledTableRow>
                <MuiStyledTableCell>Order</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.type ? capitalizeFirstLetter(order?.type) : '-'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
            {showExistingOrderDetails && (
              <MuiStyledTableRow>
                <MuiStyledTableCell>Delivery Type</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.deliveryType ? order?.deliveryType?.replaceAll('_', '-') : '-'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
            {showExistingOrderDetails && (
              <MuiStyledTableRow>
                <MuiStyledTableCell>Order Type</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.pricingType ? NavOptionsTitles[order?.pricingType] : '-'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
            {showExistingOrderDetails && baseCurrency !== order?.deliveryCurrency && (
              <MuiStyledTableRow>
                <MuiStyledTableCell>Base Currency</MuiStyledTableCell>
                <MuiStyledTableCell align="right">{baseCurrency || '-'}</MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
            {showExistingOrderDetails && (
              <MuiStyledTableRow>
                <MuiStyledTableCell>Delivery Currency</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order?.deliveryCurrency || '-'}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
            <MuiStyledTableRow>
              <MuiStyledTableCell>Authorised Participant</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(order?.createdBy?.companyName),
                  order?.createdBy.onBehalfOf
                    ? order.createdBy.onBehalfOf?.companyName
                    : order?.createdBy?.companyName
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>User Email</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(order?.createdBy.email ? order.createdBy.email : user?.contact.email),
                  order?.createdBy.email ? order.createdBy?.email : user?.contact.email
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>AP Code</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(order?.authorizedParticipantCode),
                  order?.authorizedParticipantCode
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Number of Securities</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order), order?.numberOfSecurities || ZERO_UNITS)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Number of Creation Units</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order), order?.numberOfUnits || ZERO_UNITS)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Settlement Type</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {order?.settlement && renderOrderValue(Boolean(order), order?.settlement.type)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Standard Settlement Horizon</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {order?.settlement &&
                  renderOrderValue(Boolean(order), `${order?.settlement.horizon}`)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          </TableBody>
        </Table>
      )}
      {productType === 'Token' && (
        <Table noPadding={true}>
          <TableBody>
            {showExistingOrderDetails && (
              <MuiStyledTableRow>
                <MuiStyledTableCell>Order Number</MuiStyledTableCell>
                <MuiStyledTableCell align="right">{order?.externalId}</MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
            {showExistingOrderDetails && (
              <MuiStyledTableRow data-html2canvas-ignore>
                <MuiStyledTableCell>State</MuiStyledTableCell>
                <MuiStyledTableCell align="right">
                  {order && (
                    <Tag
                      label={replace(titelize(order.status), 'Ap ', 'AP ')}
                      variant={orderStatusTagVariants[order.status] as variantTypes}
                    />
                  )}
                </MuiStyledTableCell>
              </MuiStyledTableRow>
            )}
            <MuiStyledTableRow>
              <MuiStyledTableCell>Deal Date</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order?.dealDate), order?.dealDate)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Settlement Date</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {(order?.settlementDate &&
                  renderOrderValue(Boolean(order?.settlementDate), order?.settlementDate)) ||
                  '-'}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Mint/Burn</MuiStyledTableCell>
              <MuiStyledTableCell align="right">{order?.type}</MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Authorized Merchant</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(order?.createdBy?.companyName),
                  order?.createdBy.onBehalfOf
                    ? order.createdBy.onBehalfOf?.companyName
                    : order?.createdBy?.companyName
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>User Email</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(
                  Boolean(order?.createdBy.email ? order.createdBy.email : user?.contact.email),
                  order?.createdBy.email ? order.createdBy?.email : user?.contact.email
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Number of Tokens</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {renderOrderValue(Boolean(order), order?.numberOfUnits || ZERO_UNITS)}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
            <MuiStyledTableRow>
              <MuiStyledTableCell>Blockchain Transaction</MuiStyledTableCell>
              <MuiStyledTableCell align="right">
                {order?.transactionHash && order?.product?.destinationChain ? (
                  <ExplorerLink
                    destinationChain={order.product.destinationChain}
                    data={order.transactionHash}
                    type="transaction"
                  />
                ) : (
                  '-'
                )}
              </MuiStyledTableCell>
            </MuiStyledTableRow>
          </TableBody>
        </Table>
      )}
    </section>
  );
};

export default DealInformation;
