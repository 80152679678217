/* eslint-disable complexity */
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';

// components
import { FormLabel, InputAdornment, Stack, TextField } from '@mui/material';

// utils
import mergeRefs from 'utils/mergeRef';
import copyToClipboard from 'utils/copyToClipboard';

// styles
import { StyledCalendarIconWrapper } from 'components/Input/Input.styles';
import { ReactComponent as EyeIcon } from 'assets/eye.svg';
import { ReactComponent as EyeSlashIcon } from 'assets/eye-slash.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';

export type InputProps = Omit<ComponentPropsWithoutRef<'input'>, 'size'> & {
  errorMessage?: string;
  helperText?: string;
  isInvalid?: boolean;
  label?: string;
  nodeLabel?: ReactNode;
  inputType?: React.HTMLInputTypeAttribute;
  handlePoperClick?: (event: React.MouseEvent<HTMLElement>) => void;
  size?: 'small' | 'medium' | 'large';
  withClipboard?: boolean;
  disableArrows?: boolean;
  hideBorderRadius?: boolean;
};

const formatErrorMessage = (errorMessage?: string) => {
  return errorMessage?.includes('\\n')
    ? errorMessage?.split('\\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))
    : errorMessage;
};

const InputComponent = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled,
      errorMessage,
      helperText,
      isInvalid,
      label,
      nodeLabel,
      inputType,
      handlePoperClick,
      color,
      size = 'large',
      withClipboard,
      disableArrows,
      hideBorderRadius = false,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const refs = mergeRefs<HTMLInputElement>(ref, innerRef);
    const [inputTypeValue, setInputTypeValue] = useState(inputType ?? props.type);
    const dispatch = useAppDispatch();

    useEffect(() => {
      if (isInvalid) {
        innerRef?.current?.setCustomValidity('invalid');
      } else {
        innerRef?.current?.setCustomValidity('');
      }
    }, [isInvalid]);

    const handleEyeIconClick = () => {
      if (inputTypeValue === 'password') {
        setInputTypeValue('text');
      } else if (inputType === 'password') setInputTypeValue('password');
    };

    const placeholderValue = inputTypeValue === 'calendar' ? 'dd/mm/yyyy' : props?.placeholder;

    const inputSizeProps = {
      small: { size: 'small', height: 32 },
      medium: { size: 'medium', height: 40 },
      large: { size: 'medium', height: 48 },
    }[size];

    return (
      <Stack spacing="4px">
        {label && (
          <FormLabel>
            {nodeLabel ? (
              <>
                {label} &bull; {nodeLabel}
              </>
            ) : (
              label
            )}
          </FormLabel>
        )}
        <TextField
          disabled={disabled}
          {...props}
          sx={{
            ...(disableArrows && {
              '& input[type="number"]::-webkit-outer-spin-button, & input[type="number"]::-webkit-inner-spin-button':
                {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
              '& input[type="number"]': {
                '-moz-appearance': 'textfield',
              },
            }),
          }}
          inputRef={refs}
          variant="outlined"
          hiddenLabel
          size={inputSizeProps?.size as 'small' | 'medium'}
          error={isInvalid}
          helperText={isInvalid ? formatErrorMessage(errorMessage) : helperText}
          type={inputTypeValue}
          placeholder={placeholderValue}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            style: {
              height: `${inputSizeProps.height}px`,
              ...(hideBorderRadius && {
                borderRadius: 0,
              }),
            },
            inputProps: {
              step: props.step,
              min: props.min,
              max: props.max,
            },
            ...(inputType === 'password'
              ? {
                  endAdornment: (
                    <InputAdornment sx={{ marginRight: '4px' }} position="end">
                      {inputTypeValue === 'text' ? (
                        <EyeIcon onClick={handleEyeIconClick} />
                      ) : (
                        <EyeSlashIcon onClick={handleEyeIconClick} width={16} height={16} />
                      )}
                    </InputAdornment>
                  ),
                }
              : {}),
            ...(inputType === 'calendar'
              ? {
                  endAdornment: (
                    <InputAdornment sx={{ marginRight: '4px' }} position="end">
                      <StyledCalendarIconWrapper onClick={handlePoperClick}>
                        <CalendarIcon />
                      </StyledCalendarIconWrapper>
                    </InputAdornment>
                  ),
                }
              : {}),
            ...(withClipboard
              ? {
                  endAdornment: (
                    <InputAdornment sx={{ marginRight: '4px' }} position="end">
                      <StyledCalendarIconWrapper
                        onClick={() => {
                          copyToClipboard(innerRef?.current?.value ?? '');
                          dispatch(
                            createNotification({
                              message: `${innerRef?.current?.value} copied`,
                              type: 'info',
                            })
                          );
                        }}
                      >
                        <CopyIcon />
                      </StyledCalendarIconWrapper>
                    </InputAdornment>
                  ),
                }
              : {}),
          }}
          FormHelperTextProps={{
            ...(isInvalid
              ? ({
                  'data-qa-id': 'error-message',
                } as any)
              : {}),
          }}
        />
      </Stack>
    );
  }
);

export default InputComponent;
