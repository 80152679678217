import keyBy from 'lodash/keyBy';
import { CompanyCustodianWallet } from 'utils/types/wallets';
import { Instrument, Token } from 'utils/types/product';
import {
  createCustodianWalletForCompanyRequest,
  editCustodianWalletForCompanyRequest,
} from 'utils/api/wallets';
import { createNotification } from 'store/notifications/actions';
import {
  getConstituentsOptions,
  getProductOptions,
} from 'shared/Modals/Wallets/UpdateWallet/utils';
import { useMemo } from 'react';
import { useMutation } from 'react-query';
import {
  walletCreatedNotifications,
  walletEditNotifications,
} from 'shared/Notifications/wallets.notifications';
import useAppDispatch from 'hooks/useAppDispatch';
import { ModalDataType } from 'store/modals/types';
import { MODAL_ACTIONS } from 'shared/Modals/constants';
import { WalletModalData } from 'shared/Modals/Wallets/WalletsModal';
import { IModal } from 'shared/Modals/types';

export const useUpdateCustodianWalletModalActions = (
  products: Instrument[] | Token[] | undefined,
  selectedProductId: string | undefined,
  closeModal: () => void,
  onCloseModalAction: IModal['onCloseModalAction'],
  data: ModalDataType
) => {
  const dispatch = useAppDispatch();
  const { companyData, data: walletModalData } = data!;

  const wallet = (walletModalData as WalletModalData)?.wallet as CompanyCustodianWallet;
  const isNewWallet = Boolean(data.type === MODAL_ACTIONS.CREATE);

  const productsOptions = useMemo(
    () => (products && getProductOptions(products)) ?? [],
    [products]
  );

  const constituents = useMemo(() => {
    const product = products
      ? (keyBy(products, '_id')[selectedProductId ?? ''] as Instrument | Token)
      : undefined;
    return product ? product.constituentAssets : [];
  }, [selectedProductId, products, wallet]);

  const constituentOptions = useMemo(
    () => (constituents ? getConstituentsOptions(constituents) : []),
    [constituents]
  );

  const createCustodianWalletForCompanyMutation = useMutation({
    mutationFn: (formValues: CompanyCustodianWallet) =>
      createCustodianWalletForCompanyRequest(companyData?.id ?? '', formValues),
    onSuccess: (response) => {
      dispatch(
        createNotification(
          walletCreatedNotifications.success(response.data.address, companyData?.name)
        )
      );
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(walletCreatedNotifications.error(error.message), error));
    },
  });

  const editCustodianWalletForCompanyMutation = useMutation({
    mutationFn: (formValues: CompanyCustodianWallet) =>
      editCustodianWalletForCompanyRequest(wallet?._actions?.update?.uri ?? '', formValues),
    onSuccess: () => {
      dispatch(createNotification(walletEditNotifications.success(wallet.address)));
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (error: Error) => {
      dispatch(createNotification(walletEditNotifications.error(error.message), error));
    },
  });

  const handleUpdate = (formValues: CompanyCustodianWallet) => {
    if (isNewWallet) {
      createCustodianWalletForCompanyMutation.mutate(formValues);
    } else {
      editCustodianWalletForCompanyMutation.mutate(formValues);
    }
  };

  const isUpdating =
    createCustodianWalletForCompanyMutation.isLoading ||
    editCustodianWalletForCompanyMutation.isLoading;

  return { handleUpdate, isUpdating, productsOptions, constituentOptions, isNewWallet };
};
